<template>
  <div :class="{ 'background-active': isActive }" />
</template>

<script>
export default {
  props: {
    isActive: Boolean
  }
}
</script>

<style scoped>
div {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .7s;
}
.background-active {
  opacity: 0.8;
}
</style>
