<template>
  <section class="sec-2">
    <div @mouseleave="resetKagi">
      <router-link to="/about" @mouseenter.native="updateKagi('about')">
        <JumpButton title="About" subtitle="記念祭について" />
      </router-link>
      <router-link to="/program" @mouseenter.native="updateKagi('program')">
        <JumpButton title="Program" subtitle="発表内容" />
      </router-link>
      <router-link to="/map" @mouseenter.native="updateKagi('map')">
        <JumpButton title="Map" subtitle="マップ" />
      </router-link>
      <router-link to="/time-table" @mouseenter.native="updateKagi('time-table')">
        <JumpButton title="Time Table" subtitle="タイムテーブル" />
      </router-link>
      <router-link to="/access" @mouseenter.native="updateKagi('access')">
        <JumpButton title="Access" subtitle="アクセス" />
      </router-link>
    </div>
  </section>
</template>

<script>
import { publish, subscribe } from '@/lib/publisher'
import JumpButton from '@/components/JumpButton'

export default {
  components: { JumpButton },
  props: {
    jump: Function,
    isActive: Boolean
  },
  data() {
    return { currentKagiClass: '' }
  },
  methods: {
    updateKagi(name) {
      if (this.isActive) {
        publish('updateKagikakko', 'kagi-index-' + name)
      }
    },
    resetKagi() {
      if (this.isActive) {
        publish('updateKagikakko', 'kagi-home-index')
      }
    }
  },
  mounted() {
    subscribe('updateKagikakko', className => {
      if (className === 'kagi-loading') {
        this.isActive = false
      }
    })
  }
}
</script>

<style scoped>
.sec-2 {
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 100%;
}
a {
  display: block;
  height: 20%;
}
section > div {
  height: 100%;
}
</style>
