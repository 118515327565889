<template>
  <div :style="{ backgroundPosition, opacity, backgroundImage, zIndex: z }">
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    y: Number,
    z: Number,
    opacity: Number,
    onload: Function
  },
  computed: {
    backgroundPosition() {
      return `50% ${this.y}%`
    }
  },
  data() {
    return { backgroundImage: '' }
  },
  watch: {
    src() {
      this.changedSrc()
    }
  },
  mounted() {
    this.changedSrc()
  },
  methods: {
    changedSrc() {
      const img = new Image()
      img.onload = () => {
        if (this.onload) this.onload()
        this.backgroundImage = `url(${img.src})`
      }
      img.onerror = err => {
        console.error(err)
        if (this.onload) this.onload()
        this.backgroundImage = `url(${img.src})`
      }
      img.src = this.src
    }
  }
}
</script>

<style scoped>
div {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center center;
  width: 100%;
  height: 100%;
  transition: all .7s;
  background-repeat: no-repeat;
  background-size: auto 110%;
}

@media (min-aspect-ratio: 15/11) {
  div {
    background-size: 100% auto;
  }
}

/* @media (orientation: portrait) {
  div {
  }
} */

</style>
