<template>
  <div>
    <h4>「{{ title }}」</h4>
    <div class="subtitle">
      <p class="org" v-if="org !== place">
        by {{ org }}
      </p>
      <p class="place">
        @<a :href="link">{{ place }}</a>
      </p>
      <p class="category">
        ・{{ category }}
      </p>
    </div>
    <div v-if="waitInfo !== null" class="wait-time">
      待ち時間：{{ waitInfo.waitTime }}分
      &nbsp;
      {{ Math.floor((Date.now() - parseInt(waitInfo.timestamp)) / 1000 / 60) }}分前に更新
    </div>
    <p class="description">
      <slot />
    </p>
  </div>
</template>

<script>
import schoolMap from '@/lib/schoolMap'

export default {
  props: {
    title: String,
    org: String,
    place: String,
    floor: Number,
    category: String,
    waitInfo: [Object, null]
  },
  computed: {
    link() {
      if (this.floor) {
        return schoolMap[this.floor - 1]
      }
      return 'javascript:void(0)'
    },
    time() {
      if (this.waitTime === null) {
        return '情報がありません'
      }
      return `${this.waitTime}分`
    }
  }
}
</script>

<style scoped>
div {
  max-width: 600px;
  margin: 0 auto;
}
h4 {
  font-weight: normal;
  font-size: 20px;
  border-bottom: 1px solid black;
  margin: 0;
}
.subtitle {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.subtitle p {
  margin: 0;
  white-space: nowrap;
}
.description {
  text-align: left;
  margin: 10px 10px 40px 10px;
}
a {
  text-decoration: underline;
}
</style>
