<template>
  <section>
    <h2>Time Table</h2>
    <article @wheel="prevent" @touchmove="prevent">
      準備中です。
    </article>
  </section>
</template>

<script>
export default {
  props: {
    prevent: Function
  }
}
</script>

<style scoped>
section {
  position: absolute;
  top: 600%;
}
</style>
