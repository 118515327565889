import { render, staticRenderFns } from "./AccessSection.vue?vue&type=template&id=19234784&scoped=true&"
import script from "./AccessSection.vue?vue&type=script&lang=js&"
export * from "./AccessSection.vue?vue&type=script&lang=js&"
import style0 from "./AccessSection.vue?vue&type=style&index=0&id=19234784&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19234784",
  null
  
)

export default component.exports