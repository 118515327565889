<template>
  <div id="wrapper">
    <article id="news-item" @click="click">
      <div id="title">
        {{ title }}
      </div>
      <span id="date">
        {{ date }}
      </span>
      <span id="icon" :class="{ 'active': isActive }">
        <font-awesome-icon :icon="icon" />
      </span>
    </article>
    <div id="item" :style="{ 'height': computedHeight }" ref="item">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    date: String,
    type: String, // external-link, expand
    link: String // only required if type is external-link
  },
  data() {
    return {
      isReady: false,
      isActive: false,
      height: 0
    }
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'external-link':
          return 'external-link-alt'
        case 'expand':
          return 'chevron-down'
      }
    },
    computedHeight() {
      if (this.isReady) {
        return `${this.isActive ? this.height : 0}px`
      }
      return ''
    }
  },
  methods: {
    click() {
      switch (this.type) {
        case 'external-link':
          location.href = this.link
          break
        case 'expand':
          this.isActive = !this.isActive
          break
      }
    }
  },
  mounted() {
    this.height = this.$refs.item.clientHeight
    console.log(this.$refs.item.clientHeight)
    this.isReady = true
  }
}
</script>

<style scoped>
#news-item {
  width: 100%;
  border-bottom: 1px solid black;
  height: auto;
  box-sizing: border-box;
  text-align: right;
  margin-bottom: 10px;
  overflow: hidden;
}
#title {
  float: left;
}
#date {
  font-size: 10px;
  padding-right: 10px;
}
#icon {
  display: inline-block;
  transition: transform .7s ease;
}
#icon.active {
  transform: rotateZ(180deg);
}
#item {
  overflow: hidden;
  transition: height .7s ease;
}
</style>
