const touches = {}
const touchDistance = 50
const scrollInterval = 500

export default {
  data() {
    return {
      index: 0,
      beforeScrollTop: 0,
      beforeScrollTarget: null,
      isScrollInterval: false
    }
  },
  methods: {
    scroll(event) {
      if (this.isScrollInterval) return
      if (Math.abs(event.deltaY) < 3) return
      if (event.deltaY > 0 && this.index < this.sections.length - 1) {
        this.index++
      } else if (event.deltaY < 0 && this.index > 0) {
        this.index--
      }

      this.isScrollInterval = true
      setTimeout(() => {
        this.isScrollInterval = false
      }, scrollInterval)
    },
    touchstart(event) {
      for (let touch of event.touches) {
        touches[touch.identifier] = touch
      }
    },
    touchmove(event) {
      if (this.isScrollInterval) return
      for (let touch of event.changedTouches) {
        if (touches[touch.identifier]) {
          let dY = touches[touch.identifier].clientY - touch.clientY
          if (Math.abs(dY) > touchDistance) {
            this.index = Math.max(Math.min(this.index + dY / Math.abs(dY), this.sections.length - 1), 0)
            delete touches[touch.identifier]

            this.isScrollInterval = true
            setTimeout(() => {
              this.isScrollInterval = false
            }, scrollInterval)
            break
          }
        }
      }
    },
    prevent(event, target) {
      if (!target) target = event.target
      if (target.scrollHeight <= target.clientHeight) return
      let scrollTop = target.scrollTop
      if (this.beforeScrollTarget === target) {
        scrollTop = this.beforeScrollTop
      }
      let delta = 0
      if (event.deltaY) {
        delta = event.deltaY
      } else if (event.changedTouches) {
        for (let touch of event.changedTouches) {
          if (touches[touch.identifier]) {
            delta = touches[touch.identifier].clientY - touch.clientY
            break
          }
        }
      }
      if (!(delta < 0 && scrollTop === 0) &&
          !(delta > 0 && Math.abs(scrollTop - (target.scrollHeight - target.clientHeight)) < 2)) {
        event.stopPropagation()
      }
      this.beforeScrollTop = target.scrollTop
      this.beforeScrollTarget = target
    },
    jump(index) {
      this.index = index
    }
  }
}
