<template>
  <section>
    <h2>News</h2>
    <article @wheel="_prevent" @touchmove="_prevent" ref="article">
      <NewsItem title="Program を追加しました！" date="2019.8.31" type="external-link" link="/program" />
      <NewsItem title="Map を追加しました！" date="2019.8.27" type="external-link" link="/map" />
      <NewsItem title="地震の時の対応について" date="2019.8.27" type="expand">
        ・室内では、机などの下に身を隠し、揺れが収まるまで様子を見てください。ただし、壊れやすい舞台や特設の場所にはできるだけ近づかないようにしてください。<br />
        ・屋外では、建物から離れた安全な場所で、落下物から頭部を保護し、しゃがんで揺れが収まるまで様子を見てください。<br />
        ・避難口を確保して、避難場所（西高グラウンド）に避難してください。校内放送等、学校の指示に従うようにしてください。<br />
        <br />
        避難場所　：　西高グラウンド<br />
        対策本部　：　校長室<br />
        西高連絡先：　03－3333－7771<br />
        ※回線の混雑等でつながらないことがあります。<br />
        <br />
        校内案内図は<a href="/map">こちら</a>。またはパンフレットの12ページから17ページにあります。
      </NewsItem>
    </article>
  </section>
</template>

<script>
import NewsItem from '@/components/NewsItem.vue'

export default {
  components: {
    NewsItem
  },
  props: {
    prevent: Function
  },
  methods: {
    _prevent(event) {
      this.prevent(event, this.$refs.article)
    }
  }
}
</script>

<style scoped>
section {
  position: absolute;
  top: 400%;
}
article {
  height: 77%;
}
</style>
