<template>
  <section>
    <h2>About</h2>
    <article @wheel="prevent" @touchmove="prevent">
      &nbsp;&nbsp;東京都立西高等学校記念祭は今年で73回目を迎えました。そのはじまりは、70年以上前の校舎の火事からの「復興記念祭」だといわれています。<br />
      <br />
      &nbsp;受け継いできた伝統に新しいアイデアが積み重なり、当日の西高には他にはない魅力と想像もつかない驚きがあふれています。<br />
      <br/>
      &nbsp;&nbsp;秀逸なセンスが光る演劇や映画、才能と努力の結晶ともいえるパフォーマンス、読めば読むほど深みを増す出版物、一見意味不明な展示……。混沌と秩序が入り乱れる、ここでしか味わえない時間をぜひご堪能下さい。
    </article>
  </section>
</template>

<script>
export default {
  props: {
    prevent: Function
  }
}
</script>
