const observers = {}
let isPublishing = false
const publishQueue = []

export function subscribe(eventName, observer) {
  if (!observers[eventName]) {
    observers[eventName] = []
  }
  observers[eventName].push(observer)
}

export function publish(eventName, ...args) {
  // if (isPublishing) {
  //   publishQueue.push({ eventName, args })
  // }
  // isPublishing = true
  if (observers[eventName]) {
    for (let observer of observers[eventName].slice(0)) {
      observer(...args)
    }
  }
  // isPublishing = false
  // for (let queue of publishQueue.slice(0)) {
  //   publish(queue.eventName, queue.args)
  //   publishQueue.splice(publishQueue.indexOf(queue), 1)
  // }
}

export function subscribeOnce(eventName, observer) {
  const _observer = (...args) => {
    observers[eventName].splice(observers[eventName].indexOf(_observer), 1)
    observer()
  }
  subscribe(eventName, _observer)
}
