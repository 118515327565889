<template>
  <section>
    生徒有志による作成（非公式！！！！）
    <div id="links">
      <router-link to="/">
        Main Page
      </router-link>
      <br />
      <router-link to="/about">
        About
      </router-link>
      <router-link to="/program">
        Program
      </router-link>
      <router-link to="/map">
        Map
      </router-link>
      <br />
      <router-link to="/time-table">
        Time Table
      </router-link>
      <router-link to="/access">
        Access
      </router-link>
    </div>
    <div id="sns-button">
      <a href="https://twitter.com/KIJITSU73rd">
        <img src="/assets/twitter-header.png" />
      </a>
      <a href="https://www.instagram.com/kijitsu73rd/" class="to-instagram">
        <img src="/assets/instagram-header.png" />
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    prevent: Function
  }
}
</script>

<style scoped>
section {
  padding-top: 20vh;
}
#links {
  padding: 10vh 0;
  width: 100%;
}
#links > a {
  margin: 10px 20px;
  text-decoration: underline;
}
#sns-button > * {
  margin: 0 10px;
}
</style>
