<template>
  <div class="home" @wheel="scroll" @touchmove="touchmove" @touchstart="touchstart">
    <Background
      src="https://9p2lla.bn.files.1drv.com/y4mKWvxLEsMKpsGatmL6MSJde3MLLCMX8O0mYzLercZCE1F3J4Ba4ty53Z8FOcnprjQj-cyBm4YearFf8_RrgDzN9KkCzQNL9RUlMiZACXR_4qLLImHvsOHoUcf5z86SD0Mlkm1ZNlB4YbhAmnvs8Zj0Sm-jrJL7KIPHruZYs-MSdv3H8yb7oAXDzJyKxsiTH_fQn4aA1_0eQGCGqozOorNyA?width=1536&height=1125&cropmode=none"
      :y="30 + index * 10"
      :z="-3"
      :opacity="1"
      :onload="onload" />
    <Background
      src="https://9p2jla.bn.files.1drv.com/y4m839XskS6vs7tOaHUFrBgBxpQaH3-NoI6pSL4QhWOXQKu4EVYlYEuuDDY0QRQEaCozxb7cjdYpA_Hj7HXfYKyAEwWXaSMGIhIB2wU9fqbzKIcyyk8_K0_SqqThCGM3lPM6kNd--SuAWbOxXR5Oi_QEQ9TzHD6-PH4wsfcjzJIyucG1JGe94luVyf9aZfVdEUhw_Qc0Bs9QhlNcXKa277yMA?width=984&height=720&cropmode=none"
      :y="30 + index * 10"
      :z="-2"
      :opacity="index / (sections.length - 1)"
      :onload="onload" />
    <WhiteBack :isActive="index > 0" />
    <div class="items" :style="{ top }" ref="items">
      <component
        v-for="(section, i) in sections"
        :is="section"
        :prevent="prevent"
        :v-if="Math.abs(index - i) <= 1"
        :key="i"
        :style="{ top: `${i * 100}%` }"
        :onload="onload"
        :isActive="i === index" />
    </div>
    <SectionGuide
      :length="sections.length"
      :index="index"
      :jump="jump" />
  </div>
</template>

<script>
// @ is an alias to /src
import { publish, subscribe } from '@/lib/publisher'
import HeaderSection from '@/components/HeaderSection'
import IndexSection from '@/components/IndexSection'
import AboutSection from '@/components/AboutSection'
import ThemeSection from '@/components/ThemeSection'
import NewsSection from '@/components/NewsSection'
import ProgramSection from '@/components/ProgramSection'
import TimeTableSection from '@/components/TimeTableSection'
import AccessSection from '@/components/AccessSection'
import FooterSection from '@/components/FooterSection'

import Background from '@/components/Background'
import WhiteBack from '@/components/WhiteBack'
import SectionGuide from '@/components/SectionGuide'
import scrollMixin from '@/mixins/scrollMixin'

let loadedCount = 0

export default {
  name: 'home',
  mixins: [scrollMixin],
  components: {
    HeaderSection,
    IndexSection,
    AboutSection,
    ThemeSection,
    NewsSection,
    ProgramSection,
    TimeTableSection,
    AccessSection,
    FooterSection,
    Background,
    WhiteBack,
    SectionGuide
  },
  metaInfo: {
    title: '第73回都立西高校記念祭'
  },
  data() {
    return {
      sections: [
        'HeaderSection', 'IndexSection',
        'NewsSection',
        'FooterSection'
        // 'ProgramSection',
        // 'TimeTableSection', 'AccessSection'
      ],
      length: 0
    }
  },
  computed: {
    top() {
      return `${-this.index * 100}%`
    }
  },
  mounted() {
    subscribe('loaded', () => {
      publish('updateKagikakko', 'kagi-home')
    })
  },
  watch: {
    index() {
      switch (this.sections[this.index]) {
        case 'HeaderSection':
          publish('updateKagikakko', 'kagi-home')
          break
        case 'IndexSection':
          publish('updateKagikakko', 'kagi-home-index')
          break
        case 'NewsSection':
          publish('updateKagikakko', 'kagi-home-news')
          break
        case 'FooterSection':
          publish('updateKagikakko', 'kagi-home-index')
          break
      }
    }
  },
  methods: {
    onload() {
      loadedCount++
      if (loadedCount >= 3) {
        publish('loaded')
      }
    }
  }
}
</script>

<style>
.home {
  /* background-image: url('../assets/background.png'); */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.items {
  position: relative;
  transition: top .7s;
  height: 100%;
}

section {
  width: 100vw;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  text-align: center;
}

section h2 {
  font-size: 50px;
  height: 23%;
  /* line-height: 20vh; */
  font-weight: normal;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
section article {
  text-align: left;
  padding: 0 10px;
  font-family: 'Noto Serif JP';
  max-width: 600px;
  margin: auto;
  overflow-x: auto;
  height: 77vh;
}
</style>
