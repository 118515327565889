<template>
  <section class="sec-1">
    <main><img src="/assets/title2.svg" @load="onload" /></main>
    <div>
      2019.9.15 & 16<br />
      東京都立西高等学校
    </div>
    <router-link to="/main-poster" class="to-poster">
      <img src="/assets/poster-button.png" />
    </router-link>
    <router-link to="/food" class="to-food">
      <img src="/assets/food.png" />
    </router-link>
    <a href="https://twitter.com/KIJITSU73rd" class="to-twitter">
      <img src="/assets/twitter-header.png" />
    </a>
    <a href="https://www.instagram.com/kijitsu73rd/" class="to-instagram">
      <img src="/assets/instagram-header.png" />
    </a>
  </section>
</template>

<script>
export default {
  props: {
    onload: Function
  }
}
</script>

<style scoped>
section.sec-1 {
  text-align: right;
  font-family: 'Noto Serif JP';
  font-size: 3vh;
  position: absolute;
  top: 0;
}
.sec-1 div {
  position: absolute;
  top: 65%;
}
@media (orientation: landscape) {
  .sec-1 div {
    right: 30vw;
  }
}
@media (orientation: portrait) {
  .sec-1 div {
    right: 8vw;
  }
}
.sec-1 main {
  position: absolute;
  top: 42.5%;
  left: 0;
  width: 100%;
  height: 15%;
  text-align: center;
  line-height: 100%;
}
.sec-1 main img {
  height: 100%;
}
@media (orientation: portrait) {
  .sec-1 main {
    top: calc(50% - 10vw);
    height: 20vw;
  }
}
.to-food img {
  position: absolute;
  top: 75vh;
  left: 5vw;
  height: 20vh;
}
.to-poster img {
  height: 20vh;
  padding-top: 5vh;
  margin-right: -10vh;
  animation: poster-button .7s ease 1s;
  animation-fill-mode: forwards;
}
@keyframes poster-button {
  0% {
    margin-right: -10vh;
  }
  100% {
    margin-right: -1vh;
  }
}

.to-twitter img {
  position: absolute;
  top: 90%;
  right: calc(10px + 10vh);
  height: 8%;
}
.to-instagram img {
  position: absolute;
  top: 90%;
  right: 10px;
  height: 8%;
}
</style>
