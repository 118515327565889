<template>
  <section>
    <h2>Program</h2>
    <article @wheel="_prevent" @touchmove="_prevent" ref="article">
      <ProgramItem
        v-for="(item, index) in programApi"
        :key="index"
        :title="item.title"
        :org="item.org"
        :place="item.place"
        :floor="item.floor"
        :waitInfo="waitTime(item.org)"
        :category="item.category">
        {{ item.description }}
      </ProgramItem>
    </article>
  </section>
</template>

<script>
import ProgramItem from '@/components/ProgramItem.vue'

export default {
  components: {
    ProgramItem
  },
  props: {
    prevent: Function
  },
  data() {
    return {
      programApi: [],
      machiApi: []
    }
  },
  mounted() {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', '/program.json')
    xhr.onerror = err => {
      console.error(err)
    }
    xhr.onload = () => {
      this.programApi = JSON.parse(xhr.response)
    }
    xhr.send()

    const xhr2 = new XMLHttpRequest()
    xhr2.open('GET', 'https://kinensai-machi.herokuapp.com/getMachi')
    xhr2.onerror = err => {
      console.error(err)
    }
    xhr2.onload = () => {
      if (xhr2.response) {
        this.machiApi = JSON.parse(xhr2.response)
      }
    }
    xhr2.send()
  },
  methods: {
    _prevent(event) {
      this.prevent(event, this.$refs.article)
    },
    waitTime(org) {
      if (this.machiApi[org]) {
        return this.machiApi[org]
      }
      return null
    }
  }
}
</script>

<style scoped>
section {
  position: absolute;
  top: 500%;
}
article {
  text-align: center;
  max-width: none;
}
</style>
