<template>
  <section>
    <h2>Access</h2>
    <article @wheel="prevent" @touchmove="prevent">
      <h5><span class="bold">京王井の頭線久我山駅</span>をご利用の方</h5>
      &nbsp;&nbsp;&nbsp;&nbsp;→ 駅より徒歩10分
      <br />
      ○主要駅からの所要時間<br />
      <img src="/assets/rosen.png" /><br />
      ※久我山駅は急行電車停車駅です。<br />
      <br />
      <h5><span class="bold">中央線西荻窪駅</span>をご利用の方</h5>
      <br />
      杉並区コミュニティバスすぎ丸<br />
      &nbsp;&nbsp;西荻窪駅乗車：<span class="underline">かえで路線</span> （久我山駅行）<br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;→ <span class="bold">西高校西門前</span>下車  徒歩すぐ<br />
      <br />
      <h5><span class="bold">関東バス</span>をご利用の方</h5>
      <span class="bold">荻窪駅より</span><br />
      &nbsp;&nbsp;荻窪駅南口乗車：<span class="underline">荻60系統</span>（南荻窪二丁目行）<br />
      <br />
      <span class="bold">吉祥寺駅</span>より<br />
      &nbsp;&nbsp;吉祥寺駅北口乗車：<span class="underline">中36系統</span>（中野駅行）<br />
      <br />
      <span class="bold">中野駅</span>より<br />
      &nbsp;&nbsp;中野駅乗車：<span class="underline">中36系統</span>（吉祥寺行）<br />
      <br />
      &nbsp;&nbsp;&nbsp;&nbsp;→ <span class="bold">宮前四丁目</span>下車  徒歩3分
      <div class="iframe-parent">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.396227821802!2d139.6027853157261!3d35.69186598019181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f1fe8de3f103%3A0x3a3f40863605f27e!2z5p2x5Lqs6YO956uL6KW_6auY562J5a2m5qCh!5e0!3m2!1sja!2sjp!4v1565223394711!5m2!1sja!2sjp" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    prevent: Function
  }
}
</script>

<style scoped>
section {
  position: absolute;
  top: 700%;
}
img, iframe {
  max-width: 500px;
  width: 80vw;
}
iframe {
  filter: grayscale(100%);
}
.bold {
  font-weight: bold;
}
.underline {
  border-bottom: 1px solid black;
}
.iframe-parent {
  text-align: center;
  margin-bottom: 10px;
}
h5 {
  font-size: 1.1em;
  margin-bottom: 0;
  font-weight: normal;
}
</style>
