<template>
  <div id="app">
    <transition
      mode="out-in"
      @leave="startTransition"
      @enter="finishTransition">
      <router-view v-if="isReady" />
    </transition>
    <div :class="{ 'loading-background': true, 'loading-background-active': isTransition }" />
    <div class="kagikakko kagikakko1" :class="kagiClasses">
      <img src="/assets/kagikakko.svg" />
      <transition
        name="fade"
        v-for="i in protoKagis.length"
        :key="i">
        <ProtoKagi
          v-show="isProtoKagi && currentProtoKagiIndex === i"
          :src="protoKagis[i - 1]" />
      </transition>
    </div>
    <div class="kagikakko kagikakko2" :class="kagiClasses">
      <img src="/assets/kagikakko.svg" />
      <transition
        name="fade"
        v-for="i in protoKagis.length"
        :key="i">
        <ProtoKagi
          v-show="isProtoKagi && currentProtoKagiIndex === i"
          :src="protoKagis[i - 1]" />
      </transition>
    </div>
    <div :class="{ 'loading-text': true, 'loading-text-active': isTransition }">Now Loading...</div>
  </div>
</template>

<script>
import { subscribe, publish, subscribeOnce } from './lib/publisher'
import ProtoKagi from '@/components/ProtoKagi'
import { clearInterval, setInterval } from 'timers';

let intervalId = null

export default {
  components: {
    ProtoKagi
  },
  data() {
    return {
      isTransition: true,
      kagiClasses: '',
      isReady: false,
      isProtoKagi: false,
      currentProtoKagiIndex: 1,
      protoKagis: [
        'https://lh3.googleusercontent.com/wqDn6woLVR7q4OuWl9StN36-vrIefb6i10HcYQ93Doht4QCGVCeupCVfluueaYQa-QtCw3KCRbGFAPOF0LGU_z1F4hCu12az6uyfiyMKfFAfq4LFjC10RcHPJbcN_T1zSnsS_7tzxjGKJWVJe_1mMMwEy275sTINQSwXDdQ171_6IDLZGQaMxcsiWHL5ELxVKj3KxBb9DVXyPR4e2MUe8hdtjMEIVEicVyAB0tBhHQtYXTKSLwNJekDiMPFQONqQEcmTaojWBfaDD_8BQhtmpj79hyg4JeuJdUsjMxDfis8c3-W49_dAxzsN_CSZoaG5CtVj98tZG5Bz4gilnKz2N6MoVlTSwUsscDZ79aisPjMT9sk808PQQIhPMw2txCT1ZKoOOLpMiuU1s2Phklqm5xUzPFnft51c2bFPdKWdKUuZi_FxU20QoCd_tc4DkfECblkF_cMK5YWw7zjC3GhsEy3_j0R8XwE6CDKuw7466nhcFdqOEkoqq3bRk336ws6FbGcqrVSpTGlq81JDmDWEyqyWYtK58NeiT4V4MEQxoPRZK93PncaPUATbxW0bmKRnqlWUuLoBOQtFjtTLd6ciE4ScaL-5fWZcInMBB_qf12xCVvzOK8O6piqAYBMmw7TD1NA_cTbcFHRTX59sCrS07VPi1aG_lZ6wbt4ZRZf7kOyy0RjIvNbVCOXxJ4Zx7vweFSVSkQnrZSR5jhVYbCavag4ZtQ=w80-h139-no',
        'https://lh3.googleusercontent.com/gmzCTCKtEhwmewgnUkAubZwhJghkiMJ5J6WOFxguGhjYxdeAl8oSG1s_LlM7pXii44denXXptf7MfnnR6CUZYidP7aSst4OH_Cy99N4THA_bomyJzhKw6VoAMrDiI-3tdgy762lz5H1sPAnmGm47iC6RpPguPLC0HuOn364-2QzOiTOXtUiHdCv8xA8mkEBN_kDjAJFcNDT5j4cvrmHcVyOqfKiBXEjPI54CoRT4c9d1ZtcCSBTAWad6u41AL-fsBGuhIXvSNMJkOFw0e85wyJxsU-X2nR_dCpr5qaSVrtbOee2blXbijuNPeZc_rSnVtIhKgt0lgC1qjxbt4fNRTS5kCt5Ndiwd2FJPQDViaQeAJpw9vtUJNVsp4swb35ej-oXnRWOa_W0zzReqhuFGoQwITcDiaOa_A11_BUiuO5V-9zDifAr5XB669lFFCOhSz8LfJsGRPhPmcDF2eebsR-iZiX_qn_8L_QfFabBExoF_rDzt2eE4vcLRU1OtPIl6_GTIeD4LUuqp1_9RUDnCqjCXwXsd5_38ZcKpCc3kfB9r3RqSz--gnr8wOwZQDstLCunIdliNeaYMzamMpmDbcaEkxFSEktIy7fl0mv1p87SN6-Ts1ht_iJu-ZdqAJRx5YGRPg0vH66ZncF4NyyAURsQUBkTI-SOsa4JVGkqfiyyYuN7pDq_O8CfDD2nV-DSyfnI5wjp7xV84Elhxu9d1ov2mow=w80-h139-no',
        'https://lh3.googleusercontent.com/Rgy79rZl6tU4NDRl2ca3cRPquuxNJ0ZyIqjkH-gwpj9EU0I6tq9mgdjzacF2qTy0naZGQmKoYNVvVQIVTNgtMI3PLkSzIB0vPchrDrwvNoaa8FaSmwYX9-Asp3pahuTKBr2SpR3vO2MqGDBEWwyFfIfp-RngRXOszPPSLeQ6JI3nL2AfQQCvR0r0-NsktEz1pZxPQ1DG8YOHm5cBA9gsbAclgstdTs7ivJ09P_r_Ct5Ahr3ZrgVssmzVS8bYRTnnvq4nGmTe0bplnzGfS9apQvFAHshXtmu9WXMNoKcholsDwCxcZ1pg-rtmFa9lc-SG2G1LzMsTcKvQxP7nb_xeN9lEzOkS1S9yQaVvd__kcnc27QtSxhUHYJElBEDsIAiSBsOpdKI0_OCI58NrzXeI99dkoqXnk6GFGzve6Y_j7W7HLDn27rNx8P-ScNm4h9IjlJGigS-ONMj62Vgn1e0DNu5hFYNKOpcU7kFkNgF7HZLTKNAotUNgED-szbKegkoZ-rkmih65Ou3OGdkVNXJ7KNQgNRzcAL54FEWXQsPP1kHz-tbJOXuD9vPRULXw0ozN6k3pEdf9wDNbQnuepJuBhBBXoeNR7b3uuUxMXiH_cmQD-JrvYFOL2STmaIRu7HFhFyQRUY2UisI11uh1VazZ9Q2a2WDT45SA02fZHpc5kicluqkG8cP2rwCBWh5vn-rg7SzqG8QYBNIlnYTHZK5CSnPQ0w=w79-h138-no',
        'https://lh3.googleusercontent.com/MrBfOZdP6IQxU_HzOxU7pSYm7Z8tIO_rfVqb7nuo04QXfMrnzM_6TXqYorkNLf5iuX7giAohTp6RzRFVsQQ7GX8cy6nBbdwtp8O5PzgZacEmvuPZtu_Nl-NtP5rMSSh19cp3M3kTQVEYxOqeA_ODWIpgkQ2AuhG8CvjAp1VGGjpDmwzf8Wekk3aLWsUuoe4Nik6K7x70xe2SSH0WwlJuQg_pbib4i1Xjg4rW_xtX65IAQ_2uqGp-3tZY38nC7Ka1tre1jn21COi5O_ZLIXmEP-BObDltFUekp88Wae9l1hodkbWCXbBHDpjWZD_o2s_XudHtQE7vMRw_tl0wSkYkPfLbe6-1WT8s8b1TbdJMvuNMEHAUaSHlkZM7rY265s2iWD1NzDYiaWA4dCiKo6elebx2Cp8R6RDRGkx02kXIr-sruLQA6W_6jIjsUXPuTIkVViGtiZPQaUOarNYLQmLhjrcRs5GLL0vPb-R2VSPPGchHqGWfay4ZejCUubhTKVMmLhsG4bLuoj62Tai3D8tQq0RhdCCit3YqA-EvhaJEH9u2RoQj_opSFqTLC2a6N2RO7qUDsD4UeCGRawXFa6yGs9F86NuKU0bebOxaL6Kx37WOwO1gb521FRXBm3RrMEDY2g-sOQsX2fWr-RtQbKAc1WJJ2qCp0F_1IBDy3FzupvOjvgVX3SCaC0duDTsmV-I4qL_AYUSGedI8_xpY9GTTaDLD_g=w80-h139-no',
        'https://lh3.googleusercontent.com/mBj_gYN9Ke08oeEKrKuWKlyuPd7TVilTeYiQvKoLQEoMu4SQnmFAFxaST7-fq-n5zIx5WxszF_raonfVYphy0dklZrDXHDhurQxybBg9xDpXHJ2XFxmk8np8cVwUNEtuxS2U59pvd5yWK5_OJ3Dbxe9xp-f-4Q0sX4GqCEThEVbEkJQO026xsj_NPKrN-EhHJpWxLpLJQwYIVBjqdi14o_CfKS5MkaJcCnVjxBL4k9K9OHxHyTbmEZ1cHL1A5qV_Z6UzLxKMF8JIul8UuhQCln0iagqrl985NZWM5pXzwgljTzCpo0PxaWBMV_BX7Ix5SwlIgrucg1bW45LJ7TVsPkaoIuqU-_oet6w9UQZPFgQvo7duSM2sCusFEVTjuNwP1p86qXLq2NBfQrE05EsCG4aEvdN72uPzw42T1OwmIpyXBB-yfL8wYYZQPv2ijHH_WPIwATdr3RN9d5vvDYiYtDuNc25DNPZtQMxeM-oOQFHPFtThD4IHPUeL0X0qJ4X6ADUl3k_bDQAK7v4AVgS-gb1JwfqmeND_8HDESoUtuEcKrpj7Yake8H8v-E1QhdTPtx8s7oXwWZWBWOYAadCFHqMnusOIUrXonvqiEEV2ovQ9vaaNsDmh7kTOPp_8IFZldKmysoXmwfnT9-1LfETv_0LP_r_tNNtvR5ujIAWXA8iWvtknqbR0m65_j_eXYXUY_GaSL0ZsYxUGe0liPcA_9DSALw=w79-h139-no',
        'https://lh3.googleusercontent.com/0F0EUXReu6G9o0x_O3F1VRtTFDiNkV3m_ullFkD-8ErxSZegUn9wgic5emzWY2u1Jx_Egho6qh8k68SmCuZdlSJ1QIveCGkpPzgXsIZd4Gw7PihJvRXH-cNrHkMk6sT0CdBRvz12bezM15kgQ58pmnBnFnGjm2UqJSzIZAyg1JOzc8vAMJbsuzLKfv1kzswvxE77Pd8PzcY_2ji9P6qEdnitTP4O1oht5yH57O-v2nRVidT_23x9_rvP22hsdcV--Q6YN6jhqh-JYG_MplkmCXbVLWOAspnzVubmqvErWrbaG2y-AXIitpI9_ACbil_cvT_GRZzOc_kNqrK9pLkao7Johy1-gG-go84i4MYEAe7MoYgSMQllx7cepoP-kRnoa1xpfagt6XtwQFtH7XYXiW2dVamZmMvtuzXpJYFYkT1qC4HGV3tYI4vX0mTQs2vpDcedb4mLq7mquvAjkBVDpVljG33qSfzKN4knp5YjHKfM6Yz6WBbQW_cvsAY61FjF2pbHTbH1ygAany33zpnTUtJvuDbbg80S8s20c5jZ0grIi5VMwZjzoQ-9i1rI4lQZmsu2vQZ5mw2Avd0rwoq-w0n_AxTiIyWEewjWK6BIVKCp5OzXHRTSPnWybZU_w6gPLCU7jgObMSOyS9azF-MFyMqG7s8AaihXtVwd7OCmzgz0IdOC2aV8YTNhIUtDvrAef31Y9_ERndTa3TsUN7yOc4W59A=w79-h139-no',
      ]
    }
  },
  methods: {
    startTransition(el, done) {
      this.isTransition = true
      setTimeout(() => {
        done()
      }, 1000)
    },
    finishTransition(el, done) {
      subscribeOnce('loaded', () => {
        this.isTransition = false
        setTimeout(() => {
          done()
        }, 1000)
      })
    },
    stepProtoKagi() {
      this.currentProtoKagiIndex++
      if (this.currentProtoKagiIndex > this.protoKagis.length) {
        this.currentProtoKagiIndex = 1
      }
    }
  },
  watch: {
    isTransition() {
      if (this.isTransition) {
        publish('updateKagikakko', 'kagi-loading')
      }
    },
    isProtoKagi() {
      if (this.isProtoKagi) {
        intervalId = setInterval(this.stepProtoKagi, 3000)
      } else {
        if (intervalId !== null) {
          clearInterval(intervalId)
        }
      }
    }
  },
  mounted() {
    subscribe('updateKagikakko', classes => {
      this.kagiClasses = classes
    })
    publish('updateKagikakko', 'kagi-loading')

    subscribe('changeProtoKagi', isProtoKagi => {
      this.isProtoKagi = isProtoKagi
    })

    this.isReady = false
    setTimeout(() => {
      this.isReady = true
      subscribeOnce('loaded', () => {
        this.isTransition = false
      })
    }, 500)
  }
}
</script>

<style>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
* {
  font-family: 'Noto Serif JP';
}
#app {
  position: relative;
}
a {
  text-decoration: none;
  color: black;
}
.kagikakko {
  position: absolute;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.17,.79,.59,1.02);
  transform-origin: top left;
  height: 100%;
  transform: scale(0.1225);
}
.kagikakko img {
  height: 100%;
}
.kagikakko1 {
  top: 0;
  left: 0;
}
.kagikakko2 {
  transform: rotateZ(180deg);
  top: 100%;
  left: 100%;
}
.kagikakko1.kagi-loading {
  transform: translateY(40%) translateX(50vw) translateX(-10vh) scale(0.1225);
}
.kagikakko2.kagi-loading {
  transform: rotateZ(180deg) translateY(40%) translateX(50vw) translateX(-10vh) scale(0.1225);
}
.kagikakko1.kagi-home {
  transform: translateY(40%) translateX(50vw) translateX(-30vh) scale(0.1225);
}
.kagikakko2.kagi-home {
  transform: rotateZ(180deg) translateY(40%) translateX(50vw) translateX(-30vh) scale(0.1225);
}
@media (orientation: portrait) {
  .kagikakko1.kagi-home {
    transform: translateY(40%) translateX(7vw) scale(0.1225);
  }
  .kagikakko2.kagi-home {
    transform: rotateZ(180deg) translateY(40%) translateX(7vw) scale(0.1225);
  }
}
.kagikakko1.kagi-home-index {
  transform: translateY(5%) translateX(45vw) scale(0.07);
}
.kagikakko2.kagi-home-index {
  transform: rotateZ(180deg) translateY(85%) translateX(45vw) scale(0.07);
}
@media (orientation: portrait) {
  .kagikakko1.kagi-home-index {
    transform: translateY(5%) translateX(30vw) scale(0.07);
  }
  .kagikakko2.kagi-home-index {
    transform: rotateZ(180deg) translateY(85%) translateX(30vw) scale(0.07);
  }
}
.kagikakko1.kagi-index-about {
  transform: translateY(19%) translateX(50vw) translateX(-12vh) scale(0.06)
}
.kagikakko2.kagi-index-about {
  transform: rotateZ(180deg) translateY(72%) translateX(50vw) translateX(-12vh) scale(0.06)
}
.kagikakko1.kagi-index-program {
  transform: translateY(35%) translateX(50vw) translateX(-14vh) scale(0.06)
}
.kagikakko2.kagi-index-program {
  transform: rotateZ(180deg) translateY(56%) translateX(50vw) translateX(-14vh) scale(0.06)
}
.kagikakko1.kagi-index-map {
  transform: translateY(51%) translateX(50vw) translateX(-10vh) scale(0.06)
}
.kagikakko2.kagi-index-map {
  transform: rotateZ(180deg) translateY(40%) translateX(50vw) translateX(-10vh) scale(0.06)
}
.kagikakko1.kagi-index-time-table {
  transform: translateY(67%) translateX(50vw) translateX(-16vh) scale(0.06)
}
.kagikakko2.kagi-index-time-table {
  transform: rotateZ(180deg) translateY(24%) translateX(50vw) translateX(-16vh) scale(0.06)
}
.kagikakko1.kagi-index-access {
  transform: translateY(83%) translateX(50vw) translateX(-11vh) scale(0.06)
}
.kagikakko2.kagi-index-access {
  transform: rotateZ(180deg) translateY(8%) translateX(50vw) translateX(-11vh) scale(0.06)
}
.kagikakko1.kagi-home-about {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-90px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-home-about {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-90px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-home-news {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-80px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-home-news {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-80px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-home-access {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-90px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-home-access {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-90px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-main-poster {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-100px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-main-poster {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-100px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-time-table {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-150px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-time-table {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-150px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-program {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-120px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-program {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-120px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-food {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-90px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-food {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-90px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-map {
  transform: translateY(11%) translateY(-35px) translateX(50vw) translateX(-70px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko2.kagi-map {
  transform: rotateZ(180deg) translateY(89%) translateY(-35px) translateX(50vw) translateX(-70px) scale(0.07) translateX(-50%) translateY(-20%);
}
.kagikakko1.kagi-description {
  transform: translateY(15%) translateX(50vw) translateX(-5vh) scale(0.087)
}
.kagikakko2.kagi-description {
  transform: rotateZ(180deg) translateY(75%) translateX(50vw) translateX(-5vh) scale(0.087)
}
.loading-background {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .7s;
  pointer-events: none;
}
.loading-background-active {
  pointer-events: auto;
  opacity: 1;
}
.loading-text {
  position: fixed;
  top: 65%;
  text-align: center;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: opacity .7s;
  pointer-events: none;
}
.loading-text-active {
  opacity: 1;
}
.kagi-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: all .7s;
}
</style>
