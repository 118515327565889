<template>
  <div>
    <h3>{{ title }}</h3>
    <h4>{{ subtitle }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String
  }
}
</script>

<style scoped>
div {
  height: 20%;
}
h3 {
  font-family: 'Noto Serif JP';
  margin: 0;
  font-size: 5vh;
  font-weight: normal;
}
h4 {
  font-family: 'Noto Serif JP';
  margin: 5px 0 1vh 0;
  font-weight: normal;
  font-size: 2vh;
}
/* @media (orientation: landscape) and (max-height: 1000px) {
  h3 {
    font-size: 50px;
  }
  h4 {
    font-size: 20px;
  }
} */
</style>