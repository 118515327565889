<template>
  <section class="sec-3">
    <h2>Theme</h2>
    <article @wheel="prevent" @touchmove="prevent">
      &nbsp;&nbsp;今年の記念祭のテーマは「   」(かぎかっこ)です。<br />
      <br />
      &nbsp;&nbsp;中身を書かないことで、記念祭の魅力である一言では表せない多様性を斬新な形で表現しています。<br />
      <br />
      &nbsp;&nbsp;また、このテーマには「自分たちの力で新しいものを生み出そう」という意味が込められています。これは私達自身の目標であり、記念祭に来場してくださる方々へのメッセージでもあります。<br />
      <br />
      &nbsp;&nbsp;西高生がひとりひとりの才能を集結させ、西春を懸けて作り上げた第73回記念祭にぜひご期待ください。<br />
      &nbsp;&nbsp;皆さまのご来場を心からお待ちしております。<br />
      <br />
      <div>第73回記念祭実行委員長</div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    prevent: Function
  }
}
</script>

<style scoped>
.sec-3 h2 {
  font-size: 6vh;
  height: 27%;
  line-height: 11vh;
  padding: 0;
  margin-top: -4vh;
  margin-bottom: 4vh;
}
.sec-3 > article {
  height: 73%;
}
.sec-3 article div {
  text-align: right;
}
</style>
