<template>
  <section :style="{ backgroundImage: `url(${src})` }" />
</template>

<script>
export default {
  props: {
    src: String,
    zIndex: Number
  }
}
</script>

<style scoped>
section {
  position: absolute;
  z-index: 2;
  height: 100%;
  overflow: hidden;
  top: 1%;
  left: 1%;
  transition: height .7s;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.fade-enter-active {
  animation: begin .5s;
  animation-fill-mode: forwards;
}
@keyframes begin {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
.fade-leave-active {
  top: auto;
  bottom: -1%;
  background-position: bottom center;
  animation: begin .5s;
  animation-direction: reverse;
  animation-fill-mode: forwards;
}
</style>
