<template>
  <div>
    <transition-group name="guide-list">
      <p
        v-for="(n, i) in keys"
        :key="n"
        :class="{ 'active': i === index }"
        @click="jump(i)" />
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    length: Number,
    index: Number,
    jump: Function
  },
  data() {
    return {
      keys: []
    }
  },
  mounted() {
    for (let i = 0; i < this.length; i++) {
      this.keys.push(i)
    }
  },
  watch: {
    index(newIndex, oldIndex) {
      const tmp = this.keys[oldIndex]
      this.keys.splice(oldIndex, 1)
      this.keys.splice(newIndex, 0, tmp)
    }
  }
}
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 0.5px solid #777;
  margin: 5px 7px 5px 0;
  cursor: pointer;
}
.active {
  background-color: #777;
}
.guide-list-move {
  transition: transform .7s;
}
</style>